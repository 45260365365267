import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { ICookieData } from 'src/interface'

interface ICookieList {
  cookiesList: ICookieData[]
}

const CookieList = ({ cookiesList }: ICookieList) => {
  const [expanded, setExpanded] = useState(false)
  const content = [
    {
      title: 'cookie.name',
      colWidth: 150
    },
    {
      title: 'cookie.description',
      colWidth: 300
    },
    {
      title: 'cookie.origin',
      colWidth: 120
    },
    {
      title: 'cookie.duration',
      colWidth: 120
    }
  ]

  return (
    <Accordion
      sx={{
        background: 'transparent'
      }}
      onChange={() => setExpanded(!expanded)}
    >
      <AccordionSummary sx={{ padding: 0 }}>
        <Typography color='primary' variant='body2'>
          <FormattedMessage id={expanded ? 'common.showLess' : 'common.showMore'} />
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ background: '#252525', p: 0, mt: 2, overflowX: { xs: 'scroll', md: 'auto' } }}>
        <Table>
          <TableHead>
            <TableRow>
              {content.map((item, index) => (
                <TableCell key={index} sx={{ minWidth: item.colWidth }}>
                  <Typography>
                    <FormattedMessage id={item.title} />
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {cookiesList.map((item, index) => (
            <TableBody key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableRow>
                {Object.entries(item).map(([key, value]) => (
                  <TableCell key={key}>
                    <Typography variant='body2'>
                      {key === 'origin' || key === 'name' ? value : <FormattedMessage id={value} />}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableBody>
          ))}
        </Table>
      </AccordionDetails>
    </Accordion>
  )
}

export default CookieList
