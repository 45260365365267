import { Dispatch } from 'redux'
import { client } from './client'
import { DocumentNode, OperationVariables } from '@apollo/client'
import { getWorldSellers } from 'src/services'
import { FormattedMessage } from 'react-intl'
import { NotificationManager } from 'src/functions/notifications'

type DispatchQueryGQLProps = {
  query: DocumentNode
  variables?: OperationVariables
  type?: string
  key: string
}

type DispatchMutationGQLProps = {
  mutation: DocumentNode
  variables?: OperationVariables
  key: string
}

export const queryGQL = async ({ query, variables, key }: DispatchQueryGQLProps) => {
  try {
    const { data } = await client.query({ query, variables })
    return data[key]
  } catch (error) {
    let message
    if (error instanceof Error) message = error.message
    console.error(`Error on ${key} - `, message)
    throw Error(message)
  }
}

export const dispatchQueryGQL =
  ({ query, variables, type, key }: DispatchQueryGQLProps) =>
  async (dispatch: Dispatch) => {
    let payload
    try {
      const { data } = await client.query({ query, variables })
      payload = await setPayload(key, data)
      if (type) {
        dispatch({
          type,
          payload
        })
      } else {
        return payload
      }
    } catch (error) {
      let message
      if (error instanceof Error) message = error.message
      console.error(`Error on ${key} - `, message)
      throw Error(message)
    }
  }

const setPayload = async (key: string, data: any) => {
  if (key === 'getSalesAgents') {
    return { worldSellers: await getWorldSellers(), usSellers: data[key] }
  }
  return data[key]
}

export const dispatchMutationGQL = async ({ mutation, variables, key }: DispatchMutationGQLProps) => {
  try {
    const request = await client.mutate({
      mutation,
      variables
    })
    if (request.errors === undefined) {
      NotificationManager.success(<FormattedMessage id='notification.messageSent' />)
      return 200
    }
    throw new Error('Something went wrong')
  } catch (error) {
    let message
    if (error instanceof Error) message = error.message
    console.error(`Error on ${key} - `, message)
    NotificationManager.error(<FormattedMessage id='notification.systemError' />)
    return 500
  }
}
