import React from 'react'
import './Footer.css'
import { Box, IconButton, Stack } from '@mui/material'
import { Facebook, Instagram, YouTube, LinkedIn } from '@mui/icons-material'
import { BsTwitterX } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import Region from '../Region'
import { GVIconG } from 'src/constants'

export default function FooterSocial() {
  return (
    <Stack
      direction={{ xs: 'column', sm: 'row' }}
      alignItems='center'
      justifyContent='space-between'
      width='100%'
      spacing={2}
      py={2}
    >
      <Stack direction='row' spacing={2}>
        <Link data-gtm-click-footer-icon='Gemvision' className='footer-icon' to='/'>
          <Box component='img' sx={{ content: `url(${GVIconG})`, width: 22 }} alt='Gemvision - A Stuller Company' />
        </Link>
        <Region />
      </Stack>

      <Stack direction='row' spacing={0.5}>
        <IconButton
          data-gtm-click-footer-icon='Youtube'
          className='footer-icon'
          size='small'
          disableRipple
          href='https://www.youtube.com/GemvisionCorp'
          target='_blank'
          rel='noopener noreferrer'
        >
          <YouTube sx={{ color: '#fff' }} />
        </IconButton>
        <IconButton
          data-gtm-click-footer-icon='Facebook'
          className='footer-icon'
          size='small'
          disableRipple
          href='https://www.facebook.com/GemvisionCorp/'
          target='_blank'
          rel='noopener noreferrer'
        >
          <Facebook sx={{ color: '#fff' }} />
        </IconButton>
        <IconButton
          data-gtm-click-footer-icon='Instagram'
          className='footer-icon'
          size='small'
          disableRipple
          href='https://www.instagram.com/gemvisioncorp/'
          target='_blank'
          rel='noopener noreferrer'
        >
          <Instagram sx={{ color: '#fff' }} />
        </IconButton>
        <IconButton
          data-gtm-click-footer-icon='Twitter'
          className='footer-icon'
          size='small'
          disableRipple
          href='https://www.twitter.com/GemvisionCorp'
          target='_blank'
          rel='noopener noreferrer'
        >
          <BsTwitterX style={{ color: '#fff' }} />
        </IconButton>
        <IconButton
          data-gtm-click-footer-icon='Linkedin'
          className='footer-icon'
          size='small'
          disableRipple
          href='https://www.linkedin.com/company/gemvision/'
          target='_blank'
          rel='noopener noreferrer'
        >
          <LinkedIn sx={{ color: '#fff' }} />
        </IconButton>
      </Stack>
    </Stack>
  )
}
