import { gql } from '@apollo/client'
import { queryGQL } from '../../gqlActions'

const GET_USER_INFO = gql`
  query GetUserAccountInfo($userId: String!) {
    getUserAccountInfo(userId: $userId) {
      Active
      BillToAccount
      BillToAccountObj {
        AddressLine1
        AccountNumber
        City
        CompanyName
        Country
        Zip
        State
        PhoneNumber
      }
      Email
      DefaultShipToAccount
      LastName
      FirstName
      LoginId
      MemberId
      ShipToAccountObjs {
        AccountNumber
        CompanyName
        AddressLine1
        City
        Country
        State
        Zip
        PhoneNumber
      }
      Roles
      ShipToAccounts
      ShipToAccountsHash
      rjoelegible
    }
  }
`

export const getUserInfoGQL = async (userName: string): Promise<any> => {
  return queryGQL({ query: GET_USER_INFO, variables: { userId: userName }, key: 'getUserAccountInfo' })
}
