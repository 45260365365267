export const BASE_URL = process.env.REACT_APP_ENDPOINT
export const BASE_URL_STRIPE = `${BASE_URL}/stripe`
export const BASE_URL_GEMVISION = `${BASE_URL}/gemvision`
export const BASE_URL_GRAPHQL = `${BASE_URL}/graphql`
export const DEALERS_ENDPOINT = 'https://4nenooejwc.execute-api.us-east-1.amazonaws.com/Prod'
export const DEALERS_LAMBDA_URL = DEALERS_ENDPOINT + '/dealers/public'
export const COUNTRIES_LAMDA_URL = DEALERS_ENDPOINT + '/country'
export const MilliSecondsInAWeek = 604800000
export const MatrixGoldIcon = 'https://gemvision.com/Images/MatrixGold/matrixgold_dark_icon.svg'
export const CounterSketchIcon = 'https://gemvision.com/Images/CounterSketch/sales_logo_countersketch.svg'
export const ClayooIcon = 'https://gemvision.com/Images/Clayoo/sales_logo_clayoo.svg'
export const ID_CS_CERTIFICATIONS = 'prod_F9RMdwBYIXiDJn'
export const ID_MG_CERTIFICATIONS = 'prod_Fns1QS49jNIerH'
export const ID_CS_MEMBERSHIP = 'prod_FF0sMvziNUBGHP'
export const ID_MG_MEMBERSHIP = 'prod_K6VIqxjDXMV55A'
export const ID_ONE_ON_ONE = 'prod_MsGqfX8wLImCef'
export const SKU_MG_MEMBERSHIP = 'TRAINING:2028'
export const SKU_CS_INTERNATIONAL_MEMBERSHIP = 'TRAINING:2027'
export const SKU_CS_DOMESTIC_MEMBERSHIP = 'TRAINING:2026'
export const SKU_CS_ART_IN_PERSON = 'TRAINING:1016'
export const SKU_CS_ART_VIRTUAL = 'TRAINING:2044'
export const SKU_CS_SPEC_IN_PERSON = 'TRAINING:2033'
export const SKU_CS_SPEC_VIRTUAL = 'TRAINING:2043'
export const SKU_MG_ART_VIRTUAL = 'TRAINING:2046'
export const SKU_MG_SPEC_IN_PERSON = 'TRAINING:1015'
export const SKU_MG_SPEC_VIRTUAL = 'TRAINING:2045'
export const SKU_MG_MSTR_IN_PERSON = 'TRAINING:2051'
export const SKU_MG_MSTR_VIRTUAL = 'TRAINING:2052'
export const SKU_MG_ART_IN_PERSON = 'TRAINING:1021'
export const RhinoNestIcon = {
  src: 'https://meteor.stullercloud.com/das/98539856?fmt=smart-alpha',
  width: 20,
  height: 20
}
export const GVIcon = 'https://gemvision.com/Images/gemvision_logo_white.svg'
export const GVIconG = 'https://gemvision.com/Images/gemvision_logo_g_white.svg'
export const WindowsIconSrc = 'https://gemvision.com/Images/CounterSketch/windows_icon.svg'
export const MacOSIcon = 'https://gemvision.com/Images/CounterSketch/mac_os_icon.svg'
export const MatrixGoldAndCounterSketchLogo = 'https://meteor.stullercloud.com/das/122785227?wid=190&fmt=smart-alpha'
export const CounterSketchLogo = 'https://meteor.stullercloud.com/das/98824680?wid=190&fmt=smart-alpha'
export const MatrixGoldLogo = 'https://meteor.stullercloud.com/das/136194245?wid=190&fmt=smart-alpha'
export const matrixGoldSKUs = new Set(['TRAINING:2017', 'TRAINING:2028'])
export const counterSketchSKUs = new Set(['TRAINING:2026', 'TRAINING:2027', 'TRAINING:2029', 'TRAINING:2030'])
export const PER_PAGE = 6
type environmentType = 'local' | 'dev' | 'prod' | 'alpha'
const env: environmentType = document.location.hostname.includes('localhost')
  ? 'local'
  : process.env.REACT_APP_current_stage === 'prod'
    ? 'prod'
    : process.env.REACT_APP_current_stage === 'dev'
      ? 'dev'
      : 'alpha'
export class config {
  public static get REGION(): string {
    return 'us-east-1'
  }
  //Stuller Cognito IDs
  public static get USER_POOL_ID(): string {
    return 'us-east-1_XSISJT4rm'
  }
  public static get APP_CLIENT_ID(): string {
    return '4se98otsgp0t66i614g8ahrufn'
  }
  public static get DOMAIN(): string {
    return DOMAIN_OBJ[env]
  }
  public static get GOOGLE_TAG_MANAGER(): string {
    return GOOGLE_TAG_MANGER_OBJ[env]
  }
  public static get MATRIXGOLD_DEMO_GEMVISION(): string {
    return MATRIXGOLD_DEMO_GEMVISION_OBJ[env]
  }
  public static get MATRIXGOLD_LIVE_DEMO_GEMVISION(): string {
    return MATRIXGOLD_LIVE_DEMO_GEMVISION_OBJ[env]
  }
  public static get COUNTERSKETCH_DEMO_GEMVISION(): string {
    return COUNTERSKETCH_DEMO_GEMVISION_OBJ[env]
  }
  public static get COUNTERSKETCH_LIVE_DEMO_GEMVISION(): string {
    return COUNTERSKETCH_LIVE_DEMO_GEMVISION_OBJ[env]
  }
  public static get COUNTERSKETCH_TRIAL_GEMVISION(): string {
    return COUNTERSKETCH_TRIAL_GEMVISION_OBJ[env]
  }
  public static get COUNTERSKETCH_WEBINAR_GEMVISION(): string {
    return COUNTERSKETCH_WEBINAR_GEMVISION_OBJ[env]
  }
  public static get MATRIXGOLD_WEBINAR_GEMVISION(): string {
    return MATRIXGOLD_WEBINAR_GEMVISION_OBJ[env]
  }
}
const DOMAIN_OBJ = {
  local: 'localhost',
  dev: 'dev-web.gemvision.com',
  alpha: 'alpha-web.gemvision.com',
  prod: 'gemvision.com'
}
const GOOGLE_TAG_MANGER_OBJ = {
  local: 'GTM-KRW9VM8',
  alpha: 'GTM-KRW9VM8',
  dev: 'GTM-KRW9VM8',
  prod: 'GTM-T63B5KD'
}
const MATRIXGOLD_DEMO_GEMVISION_OBJ = {
  local: 'http://localhost:3020/matrixgold-demo',
  alpha: 'https://alpha-demo.gemvision.com/matrixgold-demo',
  dev: 'https://dev-demo.gemvision.com/matrixgold-demo',
  prod: 'https://demo.gemvision.com/matrixgold-demo'
}
const COUNTERSKETCH_DEMO_GEMVISION_OBJ = {
  local: 'http://localhost:3020/countersketch-demo',
  alpha: 'https://alpha-demo.gemvision.com/countersketch-demo',
  dev: 'https://dev-demo.gemvision.com/countersketch-demo',
  prod: 'https://demo.gemvision.com/countersketch-demo'
}
const MATRIXGOLD_LIVE_DEMO_GEMVISION_OBJ = {
  local: 'http://localhost:3050/matrixgold-demo',
  alpha: 'https://alpha-livedemo.gemvision.com/matrixgold-demo',
  dev: 'https://dev-livedemo.gemvision.com/matrixgold-demo',
  prod: 'https://livedemo.gemvision.com/matrixgold-demo'
}
const COUNTERSKETCH_LIVE_DEMO_GEMVISION_OBJ = {
  local: 'http://localhost:3050/countersketch-demo',
  alpha: 'https://alpha-livedemo.gemvision.com/countersketch-demo',
  dev: 'https://dev-livedemo.gemvision.com/countersketch-demo',
  prod: 'https://livedemo.gemvision.com/countersketch-demo'
}
const COUNTERSKETCH_TRIAL_GEMVISION_OBJ = {
  local: 'http://localhost:3020/countersketch-trial',
  alpha: 'https://alpha-demo.gemvision.com/countersketch-trial',
  dev: 'https://dev-demo.gemvision.com/countersketch-trial',
  prod: 'https://demo.gemvision.com/countersketch-trial'
}
const COUNTERSKETCH_WEBINAR_GEMVISION_OBJ = {
  local: 'http://localhost:3040/countersketch-webinar',
  alpha: 'https://alpha-webinars.gemvision.com/countersketch-webinar',
  dev: 'https://dev-webinars.gemvision.com/countersketch-webinar',
  prod: 'https://webinars.gemvision.com/countersketch-webinar'
}
const MATRIXGOLD_WEBINAR_GEMVISION_OBJ = {
  local: 'http://localhost:3040/matrixgold-webinar',
  alpha: 'https://alpha-webinars.gemvision.com/matrixgold-webinar',
  dev: 'https://dev-webinars.gemvision.com/matrixgold-webinar',
  prod: 'https://webinars.gemvision.com/matrixgold-webinar'
}
