import React from 'react'
import { Box, IconButton, Stack, Typography, Button, Dialog, Slide } from '@mui/material'
import { TransitionProps } from '@mui/material/transitions'
import { FormattedMessage } from 'react-intl'
import CloseIcon from '@mui/icons-material/Close'
import CookieSettings from '../components/Cookies/CookieSettings'
import { useCookies } from 'react-cookie'
import { enableAllCookies } from 'src/utils/cookies'

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />
})

interface ICookieDialogProps {
  openDialog: boolean
  onClose: () => void
}

const CookieDialog = ({ openDialog, onClose }: ICookieDialogProps) => {
  const [, setCookie] = useCookies()

  const handleAcceptCookies = () => {
    enableAllCookies(true, setCookie)
    onClose()
  }

  const handleDenyCookies = () => {
    enableAllCookies(false, setCookie)
    onClose()
  }
  return (
    <Dialog data-cy='cookies-dialog' open={openDialog} TransitionComponent={Transition} keepMounted maxWidth='lg'>
      <Box sx={{ py: 2, px: { xs: 2, md: 6 }, position: 'relative' }}>
        <Stack
          sx={{
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: 'space-between',
            alignItems: { xs: 'self-start', md: 'center' },
            gap: 2,
            mr: 4
          }}
        >
          <Typography variant='h4' component='h2'>
            <FormattedMessage id='cookies.customizeCookies' />
          </Typography>
          <Stack
            sx={{
              flexDirection: { xs: 'column', md: 'row' },
              gap: 2,
              width: { xs: '100%', md: 'auto' },
              justifyContent: 'end'
            }}
          >
            <Button variant='outlined' onClick={handleDenyCookies}>
              <FormattedMessage id='cookies.denyCookies' />
            </Button>
            <Button variant='outlined' onClick={handleAcceptCookies}>
              <FormattedMessage id='cookies.acceptCookies' />
            </Button>
          </Stack>
        </Stack>
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            right: 8,
            height: '100%'
          }}
        >
          <IconButton
            data-cy='close-dialog'
            aria-label='close'
            onClick={onClose}
            sx={{
              position: 'sticky',
              top: { xs: '.5rem', md: '.7rem' },
              right: { xs: '.5rem', md: '.7rem' }
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box mt={2}>
          <CookieSettings />
        </Box>
      </Box>
    </Dialog>
  )
}

export default CookieDialog
