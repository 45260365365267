import React from 'react'
import './Footer.css'
import { styled } from '@mui/material/styles'
import { AccordionDetails, AccordionSummary, Accordion, Typography, Stack, Box } from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

const AccordionPanel = styled(Accordion)({
  textAlign: 'left',
  width: '100%',
  maxWidth: 1080,
  margin: '0 !important' /* To avoid movement while expanding */,
  background: 'transparent',
  boxShadow: 'none',
  borderBottom: '1px solid #444',
  borderRadius: 0,
  padding: 0,
  '&:first-of-type': {
    borderTop: '1px solid #444'
  }
})

const AccordionPanelSummary = styled(AccordionSummary)({
  padding: 0,
  minHeight: '48px !important',
  '&.Mui-expanded': {
    minHeight: '48px !important'
  },
  '& .MuiAccordionSummary-content': {
    margin: '0 !important'
  },
  '& .MuiAccordionSummary-content.Mui-expanded': {
    margin: '0 !important'
  }
})

const AccordionPanelDetails = styled(AccordionDetails)({
  display: 'block',
  padding: 0,
  paddingBottom: '1rem'
})

const FooterMenuMobile = () => {
  const [expanded, setExpanded] = React.useState('')
  const handleChange = (newExpanded: string) => setExpanded(newExpanded !== expanded ? newExpanded : '')

  return (
    <Box py={1} className='footernav-mobile'>
      <AccordionPanel expanded={expanded === 'panel1'} onChange={() => handleChange('panel1')}>
        <AccordionPanelSummary
          expandIcon={<ExpandMore sx={{ color: '#bbb' }} />}
          aria-controls='panel1d-content'
          id='panel1d-header'
        >
          <Typography>
            <FormattedMessage id='nav.products' />
          </Typography>
        </AccordionPanelSummary>
        <AccordionPanelDetails>
          <Stack>
            <Link to='/matrixgold' data-gtm-click-footer-link='Matrixgold'>
              <FormattedMessage id='nav.matrixgold' />
            </Link>
            <Link to='/countersketch' data-gtm-click-footer-link='Countersketch'>
              <FormattedMessage id='nav.countersketch' />
            </Link>
          </Stack>
        </AccordionPanelDetails>
      </AccordionPanel>
      <AccordionPanel expanded={expanded === 'panel2'} onChange={() => handleChange('panel2')}>
        <AccordionPanelSummary
          expandIcon={<ExpandMore sx={{ color: '#bbb' }} />}
          aria-controls='panel2d-content'
          id='panel2d-header'
        >
          <Typography>
            <FormattedMessage id='nav.education' />
          </Typography>
        </AccordionPanelSummary>
        <AccordionPanelDetails>
          <Stack>
            <Link to='/education/matrixgold' data-gtm-click-footer-link='Matrixgold_Training'>
              <FormattedMessage id='nav.matrixgoldTraining' />
            </Link>
            <Link to='/education/countersketch' data-gtm-click-footer-link='Countersketch_Training'>
              <FormattedMessage id='nav.countersketchTraining' />
            </Link>
            <Link to='/education/educational-partners' data-gtm-click-footer-link='Educational_Partners'>
              <FormattedMessage id='learning.educationalPartners' />
            </Link>
          </Stack>
        </AccordionPanelDetails>
      </AccordionPanel>
      <AccordionPanel expanded={expanded === 'panel3'} onChange={() => handleChange('panel3')}>
        <AccordionPanelSummary
          expandIcon={<ExpandMore sx={{ color: '#bbb' }} />}
          aria-controls='panel3d-content'
          id='panel3d-header'
        >
          <Typography>
            <FormattedMessage id='nav.contact' />
          </Typography>
        </AccordionPanelSummary>
        <AccordionPanelDetails>
          <Stack>
            <Link to='/tech-support' data-gtm-click-footer-link='Support'>
              <FormattedMessage id='nav.support' />
            </Link>
            <Link to='/sales' data-gtm-click-footer-link='Sales'>
              <FormattedMessage id='nav.sales' />
            </Link>
          </Stack>
        </AccordionPanelDetails>
      </AccordionPanel>
      <AccordionPanel expanded={expanded === 'panel4'} onChange={() => handleChange('panel4')}>
        <AccordionPanelSummary
          expandIcon={<ExpandMore sx={{ color: '#bbb' }} />}
          aria-controls='panel3d-content'
          id='panel3d-header'
        >
          <Typography>
            <FormattedMessage id='nav.buy' />
          </Typography>
        </AccordionPanelSummary>
        <AccordionPanelDetails>
          <Stack>
            <Link to='/store' data-gtm-click-footer-link='Store'>
              <FormattedMessage id='nav.store' />
            </Link>
          </Stack>
        </AccordionPanelDetails>
      </AccordionPanel>
      <AccordionPanel expanded={expanded === 'panel5'} onChange={() => handleChange('panel5')}>
        <AccordionPanelSummary
          expandIcon={<ExpandMore sx={{ color: '#bbb' }} />}
          aria-controls='panel5d-content'
          id='panel5d-header'
        >
          <Typography>
            <FormattedMessage id='nav.community' />
          </Typography>
        </AccordionPanelSummary>
        <AccordionPanelDetails>
          <Stack>
            <Link to='https://forumgemvision.com/' target='_blank' rel='noopener noreferrer'>
              <FormattedMessage id='nav.gemvisionForum' />
            </Link>
          </Stack>
        </AccordionPanelDetails>
      </AccordionPanel>
      <AccordionPanel expanded={expanded === 'panel6'} onChange={() => handleChange('panel6')}>
        <AccordionPanelSummary
          expandIcon={<ExpandMore sx={{ color: '#bbb' }} />}
          aria-controls='panel3d-content'
          id='panel3d-header'
        >
          <Typography>
            <FormattedMessage id='nav.relatedSites' />
          </Typography>
        </AccordionPanelSummary>
        <AccordionPanelDetails>
          <Stack>
            <Link
              to={'https://www.stuller.com/'}
              target='_blank'
              rel='noopener noreferrer'
              data-gtm-click-footer-link='Stuller_Dot_Com'
            >
              Stuller.com
            </Link>

            <Link
              to={'https://blog.stuller.com/'}
              target='_blank'
              rel='noopener noreferrer'
              data-gtm-click-footer-link='Stuller_Blog'
            >
              Stuller Blog
            </Link>
            <Link
              to={'https://www.stuller.com/benchjeweler'}
              target='_blank'
              rel='noopener noreferrer'
              data-gtm-click-footer-link='Stuller_Benchjeweler'
            >
              Stuller Benchjeweler
            </Link>
          </Stack>
        </AccordionPanelDetails>
      </AccordionPanel>
    </Box>
  )
}

export default FooterMenuMobile
