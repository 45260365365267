import axios from 'axios'
import { IPayment } from '../interface'
import { BASE_URL_STRIPE } from '../constants'
import { renderDate } from '.'

export const updateStripeAccountData = async (stullerUser: any, stripeAccount: any) => {
  try {
    const newData = await axios.put(`${BASE_URL_STRIPE}/customer`, {
      customerId: stripeAccount.id,
      metadata: {
        LoginId: stullerUser.LoginId,
        rjoElegible: stullerUser.rjoelegible,
        stullerBillingAccount: stullerUser.BillToAccount,
        stullerDefaultShipToAccount: stullerUser.DefaultShipToAccount
      },
      shipping: {
        address: {
          city: stullerUser.BillToAccountObj.City,
          country: stullerUser.BillToAccountObj.Country,
          line1: stullerUser.BillToAccountObj.AddressLine1,
          postal_code: stullerUser.BillToAccountObj.Zip,
          state: stullerUser.BillToAccountObj.State
        },
        name: `${stullerUser.FirstName} ${stullerUser.LastName}`,
        phone: stullerUser.BillToAccountObj.PhoneNumber
      }
    })
    console.log('User data updated')
    return newData
  } catch (error: any) {
    console.error('Error on updateStripeAccount - ', error)
    throw error
  }
}

// Note: Next method is duplicated on internal they should be in a common source
/**
 * Compare metadatas from stuller and stripe to know if stripe must be updated
 * @param stullerUser
 * @param stripeAccount
 * @returns boolean
 */
export const checkAccountData = (stullerUser: any, stripeAccount: any) => {
  const stripeStullerFields: { [key: string]: string } = {
    //fields to compare (stripeAccountField: stullerUserField)
    'metadata.LoginId': 'LoginId',
    'metadata.rjoElegible': 'rjoelegible',
    'metadata.stullerBillingAccount': 'BillToAccount',
    'metadata.stullerDefaultShipToAccount': 'DefaultShipToAccount',
    'shipping.address.city': 'BillToAccountObj.City',
    'shipping.address.country': 'BillToAccountObj.Country',
    'shipping.address.line1': 'BillToAccountObj.AddressLine1',
    'shipping.address.postal_code': 'BillToAccountObj.Zip',
    'shipping.address.state': 'BillToAccountObj.State',
    'shipping.phone': 'BillToAccountObj.PhoneNumber'
  }

  try {
    return Object.keys(stripeStullerFields).some((field: string) => {
      const stripeField = field.split('.').reduce((a: any, b: string) => a[b], stripeAccount)
      const stullerField = stripeStullerFields[field]
        .split('.')
        .reduce((a: any, b: string) => a[b], stullerUser)
        .toString()
      return stripeField.toLowerCase() !== stullerField.toLowerCase()
    })
  } catch (error: any) {
    console.warn('Warning on checkAccountData -', error?.message)
    return true
  }
}

export const getNextPayment = (payments: IPayment[], status: string) => {
  let payment = { date: '-', amount: '-' }
  if (status === 'canceled') return payment
  const currentDate = Date.now()
  const futurePayments = payments.filter((payment) => payment.date > currentDate)
  if (futurePayments.length === 0) return payment
  futurePayments.sort((a, b) => a.date - b.date)
  const nextPayment = futurePayments[0]
  payment = {
    date: renderDate(nextPayment.date * 0.001),
    amount: `$${nextPayment.amount / 100}`
  }
  return payment
}
