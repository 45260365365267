import React from 'react'
import './Footer.css'
import { Link } from 'react-router-dom'
import { Typography, Grid } from '@mui/material'
import { FormattedMessage } from 'react-intl'

const FooterMenu = () => {
  return (
    <Grid className='footernav'>
      <Grid item>
        <Typography>
          <FormattedMessage id='nav.products' />
        </Typography>
        <Link to='/matrixgold' className='footer-link' data-gtm-click-footer-link='Matrixgold'>
          <FormattedMessage id='nav.matrixgold' />
        </Link>
        <Link to='/countersketch' className='footer-link' data-gtm-click-footer-link='Countersketch'>
          <FormattedMessage id='nav.countersketch' />
        </Link>
      </Grid>
      <Grid item>
        <Typography>
          <FormattedMessage id='nav.education' />
        </Typography>
        <Link to='/education/matrixgold' className='footer-link' data-gtm-click-footer-link='Matrixgold_Training'>
          <FormattedMessage id='nav.matrixgoldTraining' />
        </Link>
        <Link to='/education/countersketch' className='footer-link' data-gtm-click-footer-link='Countersketch_Training'>
          <FormattedMessage id='nav.countersketchTraining' />
        </Link>
        <Link
          to='/education/educational-partners'
          className='footer-link'
          data-gtm-click-footer-link='Educational_Partners'
        >
          <FormattedMessage id='learning.educationalPartners' />
        </Link>
      </Grid>
      <Grid item>
        <Typography>
          <FormattedMessage id='nav.contact' />
        </Typography>
        <Link to='/tech-support' className='footer-link' data-gtm-click-footer-link='Support'>
          <FormattedMessage id='nav.support' />
        </Link>
        <Link to='/sales' className='footer-link' data-gtm-click-footer-link='Sales'>
          <FormattedMessage id='nav.sales' />
        </Link>
      </Grid>
      <Grid item>
        <Typography>
          <FormattedMessage id='nav.buy' />
        </Typography>
        <Link to='/store' className='footer-link' data-gtm-click-footer-link='Store'>
          <FormattedMessage id='nav.store' />
        </Link>
        <Typography mt={2}>
          <FormattedMessage id='nav.community' />
        </Typography>
        <Link to='https://forumgemvision.com/' className='footer-link' target='_blank' rel='noopener noreferrer'>
          <FormattedMessage id='nav.gemvisionForum' />
        </Link>
      </Grid>
      <Grid item>
        <Typography>
          <FormattedMessage id='nav.relatedSites' />
        </Typography>
        <Link
          to={'https://www.stuller.com/'}
          className='footer-link'
          target='_blank'
          rel='noopener noreferrer'
          data-gtm-click-footer-link='Stuller_Dot_Com'
        >
          Stuller.com
        </Link>
        <Link
          to={'https://blog.stuller.com/'}
          className='footer-link'
          target='_blank'
          rel='noopener noreferrer'
          data-gtm-click-footer-link='Stuller_Blog'
        >
          Stuller Blog
        </Link>
        <Link
          to={'https://www.stuller.com/benchjeweler'}
          className='footer-link'
          target='_blank'
          rel='noopener noreferrer'
          data-gtm-click-footer-link='Stuller_Benchjeweler'
        >
          Stuller Benchjeweler
        </Link>
      </Grid>
    </Grid>
  )
}

export default FooterMenu
