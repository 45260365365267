import React, { useEffect, useState } from 'react'
import { Container } from '@mui/material'
import { IContactUsForm, IReducer } from '../../interface'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import ContactUsForm from '../../components/ContactUs/ContactUsForm'
import { sendEmailToTrainingGQL } from 'src/services/graphql/schemas/emails'

const ContactUsTraining = (props: { selectedProduct: string }) => {
  const navigate = useNavigate()
  const [initialValuesData, setInitialValuesData] = useState({})
  const userInfo = useSelector((state: IReducer) => state.accountReducer.userInfo)
  const isAuthenticated = useSelector((state: IReducer) => state.accountReducer.isAuthenticated)
  const [isLoading, setLoading] = useState(false)
  const { selectedProduct } = props

  /**
   * Calls sendEmailToTrainingGQL, if it returns status code 200, confirmation url is pushed into the history
   * @function onSubmit
   * @param {IContactUsForm} values - values of the form.
   * @returns  void
   */
  const onSubmit = async (values: IContactUsForm) => {
    setLoading(true)
    let userData
    if (userInfo) {
      userData = {
        FirstName: values.name,
        Email: values.email,
        PhoneNumber: values.phone,
        AccountNumber: userInfo.DefaultShipToAccount
      }
      const success = await sendEmailToTrainingGQL(userData, selectedProduct, values.comment)
      if (success === 200) {
        return navigate('/private-classes/confirmation', { replace: true })
      }
      setLoading(false)
    }
  }

  const cancelRequest = () => {
    navigate('/store', { replace: true })
  }

  useEffect(() => {
    if (!selectedProduct) {
      cancelRequest()
    }
  }, [])

  useEffect(() => {
    if (isAuthenticated) {
      setInitialValuesData({
        name: userInfo.FirstName,
        email: userInfo.Email,
        phone: userInfo.BillToAccountObj.PhoneNumber,
        AccountNumber: userInfo.DefaultShipToAccount
      })
    }
  }, [])

  return (
    <Container data-cy='contactus-container'>
      <ContactUsForm
        isLoading={isLoading}
        initialValuesData={initialValuesData}
        onSubmit={onSubmit}
        disabledField={true}
      />
    </Container>
  )
}

export default ContactUsTraining
