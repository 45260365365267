import { gql } from '@apollo/client'
import { IContactUs, IDealerWorld, IUserInfo } from 'src/interface'
import { dispatchMutationGQL } from '../../gqlActions'

const SEND_EMAIL_TO_DEALER = gql`
  mutation SendEmailToDealer(
    $userInfo: UserInfo!
    $productInfo: String!
    $dealersToSendEmail: [Dealer!]!
    $comments: String
  ) {
    sendEmailToDealer(
      userInfo: $userInfo
      productInfo: $productInfo
      dealersToSendEmail: $dealersToSendEmail
      comments: $comments
    )
  }
`

const SEND_EMAIL_TO_TRAINING = gql`
  mutation SendEmailTraining($userInfo: UserInfo!, $productInfo: String!, $comments: String) {
    sendEmailTraining(userInfo: $userInfo, productInfo: $productInfo, comments: $comments)
  }
`

/**
 * Sends request to /sold endpoint and dispatch an event. If error, dipatch an event error
 * @param userInfo - The user information.
 * @param {string} productInfo - product name.
 * @param {string} comments - User comments on the form.
 * @returns status code
 */
export const sendEmailToDealerGQL = async (
  userInfo: IUserInfo | { FirstName: string; Email: string; PhoneNumber?: string; AccountNumber?: string },
  productInfo: string,
  dealersToSendEmail: IDealerWorld[],
  comments: string
) => {
  return await dispatchMutationGQL({
    mutation: SEND_EMAIL_TO_DEALER,
    variables: { userInfo, productInfo, dealersToSendEmail, comments },
    key: 'sendEmailToDealer'
  })
}

/**
 * Sends request to /training endpoint, dispatch an event and return status code. If error, dipatch an event error
 * @param userInfo - The user information.
 * @param {string} productInfo - product name.
 * @param {string} comments - User comments on the form.
 * @returns status code
 */
export const sendEmailToTrainingGQL = (userInfo: IContactUs, productInfo: string, comments: string) => {
  return dispatchMutationGQL({
    mutation: SEND_EMAIL_TO_TRAINING,
    variables: { userInfo, productInfo, comments },
    key: 'sendEmailToTraining'
  })
}
