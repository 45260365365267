import React from 'react'
import App from './App'
import './index.css'
// Allow us to create the Store
import { createStore, applyMiddleware } from 'redux'
//Connect to the Store
import { Provider, connect } from 'react-redux'
import thunk from 'redux-thunk'
import reducer from './reducer'
import { IntlProvider } from 'react-intl'
import { IReducer } from './interface'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import { CookiesProvider } from 'react-cookie'
import { initializeGoogleTagManager } from './utils/cookies'
import ReactDOM from 'react-dom/client'

// Create the Store
const myStore = createStore(reducer, {}, composeWithDevTools(applyMiddleware(thunk)))

// This function will map the current redux state to the props for the component that it is "connected" to.
// When the state of the redux store changes, this function will be called, if the props that come out of
// this function are different, then the component that is wrapped is re-rendered.

function mapStateToProps(state: IReducer) {
  const { lang, messages } = state.changeLanguage!
  return { locale: lang, key: lang, messages: messages }
}

const ConnectedIntlProvider = connect(mapStateToProps)(IntlProvider)

declare global {
  interface Window {
    Cypress: any
    store: any
  }
}

if (window.Cypress) {
  window.store = myStore
}

// Google Tag Manager
initializeGoogleTagManager()

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <Provider store={myStore}>
    <ConnectedIntlProvider>
      <CookiesProvider>
        <App />
      </CookiesProvider>
    </ConnectedIntlProvider>
  </Provider>
)
