import * as React from 'react'
import { IconButton, Stack, Box, Accordion, AccordionSummary, AccordionDetails, Typography, Fade } from '@mui/material'
import { Close, ArrowDropDownRounded } from '@mui/icons-material'
import { Link, useNavigate } from 'react-router-dom'
import Navigation from './Navigation.json'
import { FormattedMessage } from 'react-intl'
import { useSelector, useDispatch } from 'react-redux'
import { IReducer } from '../../interface'
import { useCookies } from 'react-cookie'
import { logoutAccount } from '../../actions'
import ProfilePicture from '../Profile/ProfilePicture'
import { GVIcon, GVIconG } from 'src/constants'

function styles() {
  return {
    drawerHeader: {
      height: 68,
      px: 1,
      position: 'sticky',
      top: 0,
      zIndex: 2,
      justifyContent: { xs: 'space-between', sm: 'flex-end' },
      background: '#181818'
    },
    accordion: {
      transition: 'background-color .2s ease',
      '&:hover': {
        bgcolor: '#282828'
      }
    },
    accordionDetailsTypo: {
      fontSize: '.85rem !important',
      color: '#fff',
      fontWeight: 500,
      py: 1.25,
      px: 2,
      borderRadius: 2,
      textTransform: 'uppercase',
      textDecoration: 'none !important',
      transition: 'background-color .2s ease',
      cursor: 'pointer',
      '&:hover': {
        bgcolor: '#333'
      }
    },
    accordionSummary: {
      p: 1.5,
      pl: 2,
      textTransform: 'uppercase',
      textDecoration: 'none !important',
      fontSize: '.85rem !important',
      height: 48
    },
    gvLogo: {
      display: { xs: 'flex', sm: 'none' },
      width: { xs: 20, s: 90 },
      height: 'auto',
      mx: 2,
      content: {
        xs: `url(${GVIconG})`,
        s: `url(${GVIcon})`
      }
    }
  }
}

interface OpenStateProps {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export default function MenuHamburgerList({ open, setOpen }: OpenStateProps) {
  const { drawerHeader, accordion, accordionDetailsTypo, accordionSummary, gvLogo } = styles()
  const accountReducer = useSelector((state: IReducer) => state.accountReducer)
  const [, , removeCookies] = useCookies(['country_code', 'temporary_user_id'])
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleLogout = () => {
    removeCookies('country_code', { path: '/' })
    removeCookies('temporary_user_id', { path: '/' })
    dispatch(logoutAccount())
    navigate('/')
  }

  const Login = () => {
    return (
      <Accordion sx={{ ...accordion }}>
        <AccordionSummary onClick={() => setOpen(false)} component={Link} to='/login' sx={{ ...accordionSummary }}>
          <FormattedMessage id='account.logIn' />
        </AccordionSummary>
      </Accordion>
    )
  }

  const Profile = () => {
    return (
      <Box>
        <Accordion sx={{ ...accordion }}>
          <AccordionSummary
            expandIcon={<ArrowDropDownRounded />}
            sx={{ ...accordionSummary, textTransform: 'capitalize', fontSize: '1rem !important' }}
          >
            <ProfilePicture firstName={accountReducer.userInfo.FirstName} />
            {accountReducer.userInfo.FirstName}
          </AccordionSummary>
          <AccordionDetails sx={{ p: 2, pt: 1 }}>
            <Stack>
              <Typography
                data-gtm-click-navbar-section='User_Area'
                onClick={() => setOpen(false)}
                component={Link}
                to='/user-area'
                variant='h6'
                sx={{ ...accordionDetailsTypo }}
              >
                <FormattedMessage id='account.userArea' />
              </Typography>
              <Typography onClick={() => handleLogout()} variant='h6' sx={{ ...accordionDetailsTypo }}>
                <FormattedMessage id='account.logOut' />
              </Typography>
            </Stack>
          </AccordionDetails>
        </Accordion>
      </Box>
    )
  }
  return (
    <Box sx={{ width: { xs: '100vw', sm: 400 } }}>
      <Stack direction='row' alignItems='center' sx={{ ...drawerHeader }}>
        <Fade timeout={1000} in={open}>
          <Box component='img' sx={{ ...gvLogo }} alt='Gemvision - A Stuller Company' />
        </Fade>
        <IconButton className='nav-btn' onClick={() => setOpen(false)}>
          <Close />
        </IconButton>
      </Stack>
      <Stack gap={1} p={2} pb={3}>
        <Box mb={2}>{accountReducer.isAuthenticated === true ? <Profile /> : <Login />}</Box>
        {Navigation.map((navigation, index) => (
          <Accordion key={index} sx={{ ...accordion }}>
            <AccordionSummary
              data-gtm-click-navbar-section={navigation.gtmData}
              onClick={() => {
                if (!navigation.dropdown) setOpen(false)
              }}
              component={navigation.url ? Link : 'div'}
              to={navigation.url}
              expandIcon={navigation.dropdown && <ArrowDropDownRounded />}
              sx={{ ...accordionSummary }}
            >
              {navigation.icon && <Box component='img' height={20} mr={1.5} my='auto' src={navigation.icon} />}
              <FormattedMessage id={navigation.title} />
            </AccordionSummary>
            {navigation.dropdown && (
              <AccordionDetails sx={{ p: 2, pt: 0.5 }}>
                <Stack>
                  {navigation.dropdown.map((dropdown, index) => (
                    <Typography
                      data-gtm-click-navbar-section={dropdown.gtmData}
                      onClick={() => setOpen(false)}
                      component={Link}
                      key={index}
                      to={dropdown.url}
                      variant='h6'
                      sx={{ ...accordionDetailsTypo }}
                    >
                      <FormattedMessage id={dropdown.title} />
                    </Typography>
                  ))}
                </Stack>
              </AccordionDetails>
            )}
          </Accordion>
        ))}
      </Stack>
    </Box>
  )
}
