import { GET_SELLERS_DATA } from '../actions'
import { IActionObject, IDealerWorld } from '../interface'
import ChrisBurgerContact from '../resources/data/ChrisBurgerContact.json'

const initialState = { dealersAgents: { dealers: [], domestic: [] } }
export const dataContactReducer = (state = initialState, action: IActionObject) => {
  switch (action.type) {
    case GET_SELLERS_DATA:
      const { worldSellers, usSellers } = action.payload
      const dealersAgents = {
        dealers: worldSellers,
        domestic: usSellers
      }
      dealersAgents.dealers.forEach((dealer: IDealerWorld) => {
        if (dealer.Url && !dealer.Url.startsWith('http://') && !dealer.Url.startsWith('https://'))
          dealer.Url = 'http://' + dealer.Url + '/'
      })
      dealersAgents.dealers.push(ChrisBurgerContact)
      return { ...state, dealersAgents: dealersAgents }
    default:
      return state
  }
}
