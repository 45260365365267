import React from 'react'
import './Footer.css'
import { Link } from 'react-router-dom'
import { Typography, Stack } from '@mui/material'
import { FormattedMessage } from 'react-intl'

export default function FooterLegal() {
  return (
    <Stack
      direction={{ xs: 'column', md: 'row' }}
      alignItems='center'
      justifyContent='space-between'
      width='100%'
      spacing={1}
      py={2}
    >
      <Stack direction='row' alignItems='center' spacing={2} flexWrap='wrap' justifyContent='center'>
        <Link to='/legal' className='footer-link' data-gtm-click-footer-link='Legal'>
          <FormattedMessage id='legal.legal' />
        </Link>
        <Link to='/legal/policy' className='footer-link' data-gtm-click-footer-link='Policy'>
          <FormattedMessage id='legal.policy' />
        </Link>
        <Link to='/legal/privacy' className='footer-link' data-gtm-click-footer-link='Privacy'>
          <FormattedMessage id='legal.privacy' />
        </Link>
        <Link to='/legal/terms' className='footer-link' data-gtm-click-footer-link='Terms_Of_Use'>
          <FormattedMessage id='legal.termsOfUse' />
        </Link>
        <Link to='/legal/cookie-settings' className='footer-link' data-gtm-click-footer-link='Cookie_Settings'>
          <FormattedMessage id='legal.cookieSettings' />
        </Link>
      </Stack>
      <Typography sx={{ color: '#bbb', fontSize: 13 }}>
        <FormattedMessage id='legal.copyrightGemvisionAllRightsReserved' values={{ year: new Date().getFullYear() }} />
      </Typography>
    </Stack>
  )
}
