import axios from 'axios'
import { Dispatch } from 'redux'
import { COUNTRIES_LAMDA_URL } from '../constants'

export * from './account'
export * from './stripe'
export * from './updatesInfo'

// --- Actions Type ---
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE'
export const GET_COUNTRIES_DATA = 'GET_COUNTRIES_DATA'
export const GET_SELLERS_DATA = 'GET_SELLERS_DATA'
export const SAVE_ROOT_BEFORE_LOGIN = 'SAVE_ROOT_BEFORE_LOGIN'
export const STRIPE_ACCOUNT = 'STRIPE_ACCOUNT'
export const TAXES_TO_APPLY = 'TAXES_TO_APPLY'
export const GET_BANNERS_DATA = 'GET_BANNERS_DATA'
export const GET_NOTIFICATIONS_DATA = 'GET_NOTIFICATIONS_DATA'
export const GET_EDUCATIONAL_PARTNERS_DATA = 'GET_EDUCATIONAL_PARTNERS_DATA'
export const SET_COUNTRY = 'SET_COUNTRY'
export const ACTIVE_MODAL = 'ACTIVE_MODAL'
export const DISACTIVE_MODAL = 'DISACTIVE_MODAL'

export function onChangeLanguage(language: string) {
  return {
    type: CHANGE_LANGUAGE,
    payload: language
  }
}

export const saveRootBeforeLogin = (root: string) => {
  return {
    type: SAVE_ROOT_BEFORE_LOGIN,
    root
  }
}

export const getCountries = () => {
  return async (dispatch: Dispatch) => {
    try {
      const request = await axios.get(COUNTRIES_LAMDA_URL)
      dispatch({
        type: GET_COUNTRIES_DATA,
        payload: request.data.results
      })
    } catch (error) {
      console.error('Error on getCountries - ', error)
    }
  }
}

export const activeModal = (url: string | undefined) => {
  return { type: ACTIVE_MODAL, payload: url }
}

export const desactiveModal = () => {
  return { type: DISACTIVE_MODAL }
}
