import { toast, ToastOptions } from 'react-toastify'

const notificationDefaultParams: ToastOptions = {
  autoClose: 5000,
  position: window.innerWidth >= 600 ? 'top-right' : 'bottom-right',
  theme: 'dark',
  className: 'notification-block',
  closeOnClick: true,
  closeButton: false
}
export class NotificationManager {
  static error(message: string | React.ReactElement) {
    toast.error(message, notificationDefaultParams)
  }
  static warning(message: string | React.ReactElement) {
    toast.warning(message, notificationDefaultParams)
  }
  static success(message: string | React.ReactElement) {
    toast.success(message, notificationDefaultParams)
  }
  static info(message: string | React.ReactElement) {
    toast.info(message, notificationDefaultParams)
  }
}
