import React, { useState } from 'react'
import { Box, Button, Stack, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { useCookies } from 'react-cookie'
import { enableAllCookies } from '../utils/cookies'
import CookieDialog from './CookieDialog'

const CookiesBanner = () => {
  const [cookies, setCookie] = useCookies()
  const [openDialog, setOpenDialog] = useState(false)

  const handleClose = () => {
    setOpenDialog(false)
  }

  const handleAcceptCookies = () => {
    enableAllCookies(true, setCookie)
    setOpenDialog(false)
  }

  const handleDenyCookies = () => {
    enableAllCookies(false, setCookie)
    setOpenDialog(false)
  }

  if (cookies.hideCookiesBanner) {
    return null
  }
  return (
    <Box
      sx={{
        zIndex: 99,
        position: 'sticky',
        bottom: 0,
        width: '100%',
        backgroundColor: '#fff'
      }}
    >
      <Stack
        maxWidth={1600}
        m='auto'
        gap={2}
        sx={{
          py: { xs: 2, md: 8 },
          px: { xs: 2, md: 8 }
        }}
      >
        <Typography component='h2' variant='h4' color='#111' textAlign='left'>
          <FormattedMessage id='cookies.bannerTitle' />
        </Typography>
        <Typography align='left' color='#111'>
          <FormattedMessage id='cookies.weUseCookies' />
        </Typography>
        <Stack
          sx={{ flexDirection: { xs: 'column-reverse', sm: 'row' }, mt: 2, justifyContent: 'space-between', gap: 2 }}
        >
          <Button color='primary' variant='outlined' onClick={() => setOpenDialog(true)} data-cy='config-cookies'>
            <FormattedMessage id='cookies.customizeCookies' />
          </Button>
          <Stack sx={{ flexDirection: { xs: 'column-reverse', sm: 'row' }, gap: 2 }}>
            <Button color='primary' variant='contained' onClick={handleDenyCookies} data-cy='cookies-deny'>
              <FormattedMessage id='cookies.denyCookies' />
            </Button>
            <Button variant='contained' color='primary' onClick={handleAcceptCookies} data-cy='cookies-accept'>
              <FormattedMessage id='cookies.acceptCookies' />
            </Button>
          </Stack>
        </Stack>
      </Stack>
      <CookieDialog openDialog={openDialog} onClose={handleClose} />
    </Box>
  )
}

export default CookiesBanner
