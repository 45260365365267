import { Box, Checkbox, Grid, Typography } from '@mui/material'
import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import CookieList from './CookieList'
import { ICookieData } from 'src/interface'

interface ICookieGroupProps {
  handleChange?: (enable: boolean) => void
  locked?: boolean
  title: string
  description: string
  checkboxValue?: boolean
  cookiesList?: ICookieData[]
}

const CookieGroup = (props: ICookieGroupProps) => {
  const { handleChange, locked, title, description, checkboxValue, cookiesList } = props
  const [isChecked, setIsChecked] = useState<boolean>(checkboxValue || false)

  const handleClick = () => {
    if (handleChange && !locked) {
      const enable = !isChecked
      setIsChecked(enable)
      handleChange(enable)
    }
  }

  return (
    <Box>
      <Grid container alignItems='center'>
        <Grid onClick={handleClick} item sm={1}>
          <Checkbox checked={locked ? true : isChecked} disabled={locked} />
        </Grid>
        <Grid item xs={10} sm={11}>
          <Typography variant='h6' component='h3' align='left'>
            <FormattedMessage id={title} />
          </Typography>
        </Grid>
        <Grid item sm={1} />
        <Grid item xs={10} sm={11} m='auto'>
          <Typography variant='body2' align='left' mb={2}>
            <FormattedMessage id={description} />
          </Typography>
          {cookiesList && <CookieList cookiesList={cookiesList} />}
        </Grid>
      </Grid>
    </Box>
  )
}

export default CookieGroup
